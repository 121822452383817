
































import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import Tab from '@/shared/classes/tab'
import can from '@/shared/helpers/can.helper'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { CompanyRoutes } from '@/shared/router/company'

@Component({
  methods: { can, __ },
})
export default class Summary extends Vue {
  tabs = [
    new Tab()
      .setTo(CompanyRoutes.projectsShowTabSummaryTabInstructionsAssigned)
      .setTitle(__('company.views.projects.show.tabs.summary.assigned-instructions.title'))
      .setPermissions([]),
    new Tab()
      .setTo(CompanyRoutes.projectsShowTabSummaryTabInstructionsTemplates)
      .setTitle(__('company.views.projects.show.tabs.summary.instruction-templates.title'))
      .setPermissions([]),
  ]

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
